<template>
  <div>
    <div class="page">
      <div class="page__title">
        {{ title }}
      </div>
      <div class="page__desc" v-html="message"></div>
    </div>
    <div class="page__button">
      <div v-if="type === 'not_register'">
        <SharedButton class="s-btn-bg-primary" @click="goToRegisterUrl">
          {{ getModuleConfig('external_member_customer_bind.not_register.confirm_text') }}
        </SharedButton>
        <SharedButton class="s-btn-outline-primary mt-2" @click="cancel">
          {{ getModuleConfig('external_member_customer_bind.not_register.cancel_text') }}
        </SharedButton>
      </div>
      <div v-else-if="type === 'overwrite_check'">
        <SharedButton class="s-btn-bg-primary" @click="overwriteConfirm">
          {{ getModuleConfig('external_member_customer_bind.overwrite_check.confirm_text') }}
        </SharedButton>
        <SharedButton class="s-btn-outline-primary mt-2" @click="cancel">
          {{ getModuleConfig('external_member_customer_bind.overwrite_check.cancel_text') }}
        </SharedButton>
      </div>
      <div v-else>
        <SharedButton class="s-btn-bg-primary" @click="cancel">
          關閉
        </SharedButton>
      </div>
    </div>
  </div>
</template>

<script>
import SharedButton from "@/components/Page/Liff/Shared/Button.vue";
import registerMixin from "@/mixins/liff/register";
import liff from "@line/liff";
import registerApi from "@/apis/liff/v2/register";

export default {
  mixins: [registerMixin],
  components: {
    SharedButton,
  },
  data() {
    return {
      content: null,
      type: "error",
    };
  },
  mounted() {
    if (this.$route.params.type) {
      this.type = this.$route.params.type;
    }
  },
  computed: {
    title() {
      switch (this.type) {
        case "not_register":
          return this.getModuleConfig('external_member_customer_bind.not_register.title');
        case "field_missed":
          return this.getModuleConfig('external_member_customer_bind.field_missed.title');
        case "overwrite_check":
          return this.getModuleConfig('external_member_customer_bind.overwrite_check.title');
        case "provider_error":
        case "error":
          return "錯誤";
      }
      return "提醒"
    },
    message() {
      switch (this.type) {
        case "not_register":
          return this.getModuleConfig("external_member_customer_bind.not_register.message")
        case "field_missed":
          return this.getModuleConfig("external_member_customer_bind.field_missed.message")
        case "overwrite_check":
          return this.getModuleConfig('external_member_customer_bind.overwrite_check.message')
        case "provider_error":
          return "系統尚有設定未正確設置，請與客服人員確認";
        case "error":
          return "發生錯誤，請與客服人員聯繫";
      }

      return "請與客服人員聯繫"
    },
  },
  methods: {
    goToRegisterUrl() {
      window.location.href = this.getModuleConfig('external_member_customer_bind.not_register.register_url');
    },
    cancel() {
      liff.closeWindow();
    },
    async overwriteConfirm() {
      try {
        let response = await registerApi.overwriteBinding({
          is_overwrite: true,
        })

        if (response.data.status === 'success') {
          this.$router.push({
            name: "LiffRegisterWelcome",
            params: {
              orgCode: this.$route.params.orgCode,
            },
            query: this.$route.query,
          });
        } else {
          this.$swal('錯誤', '複寫失敗')
        }
      } catch (error) {
        console.error(error)
        this.$swal('錯誤', '重新綁定失敗', 'error');
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.list {
  max-width: 425px;
  margin: auto;
}
.item {
  display: flex;
  margin-bottom: 16px;
  &__text {
    color: var(--liff-primary_text_color);
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
    margin-left: 8px;
  }
}
</style>
